<template>
  <div class="col-sm-10 job-template" style="margin: auto">
    <CCardGroup class="has-loading-overlay">
      <CCard>
        <CCardBody class="p-3">
          <h3 class="text-primary text-center">List Job Templates</h3>
          <CRow class="mt-4">
            <CCol md="6" class="mb-3">
              <CRow class="row">
                <label class="col-lg-12 col-md-12">Organisation</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <Select
                    name="organisation"
                    :value="templates.organisation"
                    @input="handleChangeSelect"
                    :options="
                      options && options['organisation']
                        ? options['organisation']
                        : []
                    "
                    :taggable="false"
                    :multiple="false"
                    :clearable="false"
                    :disabled="getIsChildUser"
                  />
                </div>
              </CRow>
            </CCol>
            <CCol md="6" class="mb-3">
              <CRow class="row">
                <label class="col-lg-12 col-md-12">Candidate Type</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <Select
                    name="candidate_type_id"
                    :value="templates.candidate_type_id"
                    @input="handleChangeSelect"
                    :options="
                      options && options['candidateType']
                        ? options['candidateType']
                        : []
                    "
                    :taggable="false"
                    :multiple="false"
                    :clearable="false"
                  />
                </div>
              </CRow>
            </CCol>
          </CRow>
          <CDataTable
            :items="getItems"
            :fields="fields"
            hover
            class="table_"
            v-if="templates.organisation"
          >
            <template #display_name-header>
              <div class="d-inline text-primary">
                Sections
                <em
                  v-if="!getIsChildUser"
                  class="fas fa-clone ml-1 cursor-pointer"
                  v-c-tooltip="{
                    content: 'Clone Templates',
                    placement: 'right',
                  }"
                  @click="openCloneModal()"
                />
              </div>
            </template>

            <template #expand-header>
              <div class="d-flex justify-content-end">
                <a
                  class="text-primary cursor-pointer"
                  style="font-weight: 500"
                  @click="expandAll(!expandAllSection)"
                  >{{ expandAllSection ? "- Collapse All" : "+ Expand All" }}</a
                >
              </div>
            </template>

            <template #expand="{ item, index }">
              <td class="py-2 d-flex justify-content-end">
                <a
                  class="text-primary cursor-pointer"
                  style="font-weight: 500"
                  @click="toggleDetails(item, index)"
                  >{{ Boolean(item._toggled) ? "- Collapse" : "+ Expand" }}</a
                >
              </td>
            </template>
            <template #details="{ item }">
              <CCollapse
                :show="Boolean(item._toggled)"
                :duration="collapseDuration"
              >
                <CCardBody>
                  <table
                    class="table table-section table-striped border-bottom mb-0"
                    style="table-layout: fixed"
                  >
                    <tbody>
                      <tr
                        v-for="(i, index) in getSections[item.display_id]"
                        :key="index"
                        style="background-color: white"
                      >
                        <td>
                          <div
                            class="row d-flex justify-content-between ml-0 mr-0"
                          >
                            <h6>
                              {{ i.section_name }}
                              {{ checkGeneric(i) ? " (generic)" : "" }}
                            </h6>
                            <div>
                              <em
                                v-c-tooltip="'Edit'"
                                class="fas fa-edit cursor-pointer"
                                @click="editSection(item, i)"
                              >
                              </em>
                              <em
                                v-c-tooltip="'Delete'"
                                class="fas fa-trash-can cursor-pointer ml-2"
                                @click="deleteSection(i)"
                              >
                              </em>
                            </div>
                          </div>
                          <!-- job-section css class used to remove margin bottom space in <p> tag -->
                          <p class="job-section" v-html="i.section_desc"></p>
                        </td>
                      </tr>
                      <tr
                        v-if="
                          getSections[item.display_id] &&
                          !getSections[item.display_id].length
                        "
                      >
                        <td class="text-center">No Section Template Found</td>
                      </tr>
                    </tbody>
                  </table>
                  <CRow class="d-flex justify-content-end">
                    <CButton
                      @click="addSection(item)"
                      class="mt-2 btn btn-primary small mr-3 rounded-0"
                      >Add Section</CButton
                    >
                  </CRow>
                </CCardBody>
              </CCollapse>
            </template>
          </CDataTable>
          <h5 v-else class="text-center">No Data Found</h5>
        </CCardBody>
      </CCard>
    </CCardGroup>
    <ModifySectionTemplates
      v-if="modifySectionModal.isShowPopup"
      :isShowPopup="modifySectionModal.isShowPopup"
      :sectionData="modifySectionModal.editSectionData"
      :modalTitle="modifySectionModal.modalTitle"
      :showGenericRadioButton="modifySectionModal.showGenericRadioButton"
      :candidateType="modifySectionModal.candidate_type"
      :modalCallBack="modifySectionCallBack"
    />
    <CloneSectionTemplates
      v-if="cloneSectionModal.isShowPopup"
      :isShowPopup="cloneSectionModal.isShowPopup"
      :modalTitle="cloneSectionModal.modalTitle"
      :modalCallBack="modifyCloneCallBack"
    />
    <CModal
      id="delete-section"
      title="NOTIFICATION"
      color="primary"
      :show.sync="deleteSectionModal.isShowPopup"
    >
      <p class="h6">Confirm you wish to delete this section?</p>
      <template #footer>
        <span v-for="button in ['Cancel', 'Confirm']" :key="button">
          <CButton
            class="ml-2"
            :color="button == 'Cancel' ? 'secondary' : 'primary'"
            @click="deleteSectionCallBack(button)"
          >
            {{ button }}
          </CButton>
        </span>
      </template>
    </CModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select.vue";
import { deepClone } from "@/helpers/helper";
import ModifySectionTemplates from "./ModifySectionTemplates.vue";
import CloneSectionTemplates from "./CloneSectionTemplates.vue";

export default {
  name: "ListJobTemplates",
  components: {
    Select,
    ModifySectionTemplates,
    CloneSectionTemplates,
  },
  data() {
    return {
      templates: {},
      fields: [
        {
          key: "display_name",
          label: "Sections",
          _style: "width:50%;",
        },
        { key: "expand", label: "" },
      ],
      expandAllSection: false,
      collapseDuration: 0,
      modifySectionModal: {
        isShowPopup: false,
        modalTitle: "",
        showGenericRadioButton: false,
        candidate_type: "",
        editSectionData: {},
      },
      cloneSectionModal: {
        isShowPopup: false,
        modalTitle: "Clone Templates",
      },
      deleteSectionModal: {
        isShowPopup: false,
        data: null,
      },
      display_names: [
        "Organisation Description",
        "Job Description",
        "Qualifications Required",
        "Essential Requirements",
        "Preferred Requirements",
        "Salary",
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getCustomerId",
      "getOrgID",
      "getIsChildUser",
      "organizationId",
      "candidateType",
      "getSectionByDisplayNames",
    ]),
    options() {
      return {
        organisation: this.organizationId || [],
        candidateType: this.candidateType || [],
      };
    },
    getItems() {
      return this.display_names?.map((val, id) => {
        return {
          display_name: val,
          display_id: val.split(" ").join("_"),
          _toggled: false,
          id,
        };
      });
    },
    getSections() {
      let data = this.getSectionByDisplayNames;
      return deepClone(data);
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "initJobTemplatesFetchOptions",
      "fetchSectionDisplayNames",
      "fetchSectionByDisplayNames",
      "deleteJobSectionTemplate",
    ]),
    checkGeneric(i) {
      return i?.candidate_type_id ? false : true;
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    handleChangeSelect(name, value) {
      Vue.set(this.templates, name, value);
      if (name == "organisation") {
        this.handleChangeSelect("candidate_type_id", this.candidateType[0]);
      }
      if (name == "candidate_type_id") {
        this.fetchDisplayNames();
      }
    },
    fetchDisplayNames() {
      let organisation_uid = this.templates?.organisation?.code;
      this.display_names.forEach((val) => {
        this.fetchSectionByDisplayNames({
          display_name: val,
          organisation_uid,
          customer_uid: this.getCustomerId,
          candidate_type_id: `${this.templates?.candidate_type_id?.code},null`,
        });
      });
    },
    toggleDetails(item) {
      this.$set(this.getItems[item.id], "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
      // To check expand all func
      let temp = false;
      this.getItems.forEach((val) => (val._toggled ? (temp = true) : null));
      if (temp) this.expandAllSection = false;
    },
    expandAll(bool) {
      this.getItems?.forEach((item) => {
        this.$set(this.getItems[item.id], "_toggled", bool);
        this.collapseDuration = 300;
        this.$nextTick(() => {
          this.collapseDuration = 0;
        });
      });
      this.expandAllSection = bool;
    },
    getDisplayOrder(display_name) {
      switch (display_name) {
        case "Organisation Description":
          return 1;
        case "Job Description":
          return 2;
        case "Qualifications Required":
          return 3;
        case "Essential Requirements":
          return 4;
        case "Preferred Requirements":
          return 5;
        case "Salary":
          return 6;
        default:
          return null;
      }
    },
    addSection(item) {
      let data = {
        customer_id: this.getCustomerId,
        organisation_id: this.templates?.organisation?.code,
        candidate_type_id: this.templates?.candidate_type_id?.code,
        display_name: item.display_name,
        display_order: this.getDisplayOrder(item.display_name),
      };
      this.modifySectionModal.modalTitle = "Add Section";
      this.modifySectionModal.editSectionData = data;
      if (data.display_name != "Organisation Description") {
        this.modifySectionModal.showGenericRadioButton = true;
        this.modifySectionModal.candidate_type =
          this.templates?.candidate_type_id?.label;
      }
      this.modifySectionModal.isShowPopup = true;
    },
    editSection(item, data) {
      data = {
        ...data,
        display_name: item.display_name,
        candidate_type_id: this.templates?.candidate_type_id?.code,
        ...data.customer_organisation,
      };
      this.modifySectionModal.modalTitle = "Edit Section";
      this.modifySectionModal.editSectionData = data;
      this.modifySectionModal.isShowPopup = true;
    },
    modifySectionCallBack() {
      this.modifySectionModal.isShowPopup = false;
      this.modifySectionModal.showGenericRadioButton = false;
    },
    deleteSection(data) {
      this.deleteSectionModal.data = data;
      this.deleteSectionModal.isShowPopup = true;
    },
    deleteSectionCallBack(action) {
      if (action === "Confirm") {
        let data = this.deleteSectionModal.data;
        this.deleteJobSectionTemplate(data["section_id"]).then((res) => {
          this.fetchSectionByDisplayNames({
            display_name: data?.display_name,
            organisation_uid: data.organisation_id,
            customer_uid: data.customer_id,
            candidate_type_id: `${data.candidate_type_id},null`,
          });
        });
      }
      this.deleteSectionModal.isShowPopup = false;
    },
    openCloneModal() {
      this.cloneSectionModal.isShowPopup = true;
    },
    modifyCloneCallBack(action) {
      if (action) {
        this.fetchDisplayNames();
      }
      this.cloneSectionModal.isShowPopup = false;
    },
  },
  mounted() {
    this.initJobTemplatesFetchOptions().then(() => {
      let organisation = this.options?.organisation?.filter(
        ({ code }) => code === this.getOrgID
      );
      if (this.options.organisation?.length == 1 && organisation?.length) {
        this.handleChangeSelect(
          "organisation",
          this.options["organisation"][0]
        );
      }
    });
  },
};
</script>
