var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pl-2"},[_c('CModal',{attrs:{"title":_vm.modalTitle,"color":"primary","show":_vm.isShowPopup,"closeOnBackdrop":false,"size":"lg"},on:{"update:show":function($event){_vm.isShowPopup=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h6',[_vm._v(_vm._s(_vm.modalTitle))]),_c('CButtonClose',{on:{"click":function($event){return _vm.modalCallBack(false)}}})]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{attrs:{"form":"clone_section_template","color":"secondary"},on:{"click":function($event){return _vm.modalCallBack('Cancel')}}},[_vm._v("Cancel")]),_c('CButton',{attrs:{"form":"clone_section_template","color":"primary","type":"Submit"}},[_vm._v("Confirm")])]},proxy:true}])},[_c('div',[_c('ValidationObserver',{ref:"clone_section_template",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"id":"clone_section_template"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('h6',{staticClass:"ml-3 text-justify",staticStyle:{"color":"red"}},[_vm._v(" Clone (copy) job templates from one organisation to another. ")]),_c('CRow',{staticClass:"m-0"},[_c('CCol',{staticClass:"mb-3 pt-2",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v(" Clone (copy) from ")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"source_organisation_id","value":_vm.clone.source_organisation_id,"options":_vm.options && _vm.options['source_organisation_id']
                          ? _vm.options['source_organisation_id']
                          : [],"clearable":false,"error":errors[0]},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)])],1),_c('CCol',{staticClass:"mb-3 pt-2",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Candidate Type (Optional)")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('Select',{attrs:{"name":"candidate_type_id","value":_vm.clone.candidate_type_id,"options":_vm.options && _vm.options['candidateType']
                        ? _vm.options['candidateType']
                        : [],"clearable":false},on:{"input":_vm.handleChangeSelect}})],1)])],1)],1),_c('CRow',{staticClass:"m-0"},[_c('CCol',{staticClass:"mb-3 pt-2",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v(" Apply to ")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"target_organisation_id","value":_vm.clone.target_organisation_id,"options":_vm.options && _vm.options['target_organisation_id']
                          ? _vm.options['target_organisation_id']
                          : [],"clearable":false,"error":errors[0]},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)])],1)],1),(
              _vm.clone.source_organisation_id && _vm.clone.target_organisation_id
            )?_c('div',{staticClass:"ml-3 mb-0",staticStyle:{"color":"green"}},[_c('span',[_c('strong',[_vm._v(" "+_vm._s(_vm.clone.candidate_type_id.label == "All" ? "All templates" : `Only ${_vm.clone.candidate_type_id.label} templates`)+" ")]),_vm._v(" will be cloned from "),_c('strong',[_vm._v(_vm._s(_vm.clone.source_organisation_id.label))]),_vm._v(" to "),_c('strong',[_vm._v(_vm._s(_vm.clone.target_organisation_id.label))])])]):_vm._e()],1)]}}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }