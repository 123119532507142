<template>
  <div class="pl-2">
    <CModal
      :title="modalTitle"
      color="primary"
      :show.sync="isShowPopup"
      :closeOnBackdrop="false"
      size="lg"
    >
      <template #header>
        <h6>{{ modalTitle }}</h6>
        <CButtonClose @click="modalCallBack(false)" />
      </template>

      <template #footer>
        <CButton
          form="clone_section_template"
          color="secondary"
          @click="modalCallBack('Cancel')"
          >Cancel</CButton
        >
        <CButton form="clone_section_template" color="primary" type="Submit"
          >Confirm</CButton
        >
      </template>

      <div>
        <ValidationObserver
          ref="clone_section_template"
          v-slot="{ handleSubmit }"
        >
          <form
            id="clone_section_template"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <h6 class="ml-3 text-justify" style="color: red">
              Clone (copy) job templates from one organisation to another.
            </h6>
            <CRow class="m-0">
              <CCol md="6" class="mb-3 pt-2">
                <CRow class="row">
                  <label class="required col-lg-12 col-md-12">
                    Clone (copy) from
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="source_organisation_id"
                        :value="clone.source_organisation_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['source_organisation_id']
                            ? options['source_organisation_id']
                            : []
                        "
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" class="mb-3 pt-2">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12"
                    >Candidate Type (Optional)</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="candidate_type_id"
                      :value="clone.candidate_type_id"
                      @input="handleChangeSelect"
                      :options="
                        options && options['candidateType']
                          ? options['candidateType']
                          : []
                      "
                      :clearable="false"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="m-0">
              <CCol md="6" class="mb-3 pt-2">
                <CRow class="row">
                  <label class="required col-lg-12 col-md-12"> Apply to </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="target_organisation_id"
                        :value="clone.target_organisation_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['target_organisation_id']
                            ? options['target_organisation_id']
                            : []
                        "
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <div
              class="ml-3 mb-0"
              style="color: green"
              v-if="
                clone.source_organisation_id && clone.target_organisation_id
              "
            >
              <span>
                <strong>
                  {{
                    clone.candidate_type_id.label == "All"
                      ? "All templates"
                      : `Only ${clone.candidate_type_id.label}
                  templates`
                  }}
                </strong>
                will be cloned from
                <strong>{{ clone.source_organisation_id.label }}</strong>
                to
                <strong>{{ clone.target_organisation_id.label }}</strong>
              </span>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/reusable/Modal";
import Select from "@/components/reusable/Fields/Select";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });

export default {
  extends: Modal,
  name: "CloneSectionTemplates",
  props: ["modalCallBack", "modalTitle"],
  components: {
    Select,
  },
  data() {
    return {
      clone: {
        candidate_type_id: { label: "All", code: "all" },
      },
      payload: {},
    };
  },
  computed: {
    ...mapGetters(["organizationId", "candidateType"]),
    options() {
      return {
        source_organisation_id:
          this.organizationId?.filter(
            (val) => val.code != this.payload?.target_organisation_id
          ) || [],
        target_organisation_id:
          this.organizationId.filter(
            (val) => val.code != this.payload?.source_organisation_id
          ) || [],
        candidateType:
          [{ label: "All", code: "all" }, ...this.candidateType] || [],
      };
    },
  },
  watch: {
    isShowPopup() {
      if (this.isShowPopup === true) {
        this.payload = {};
      }
      this.$nextTick(() => {
        this.$refs.clone_section_template.errors.clear;
        this.$refs.clone_section_template.reset();
      });
    },
  },
  methods: {
    ...mapActions(["clone_section_templates"]),
    handleChangeSelect(name, value) {
      Vue.set(this.clone, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
    },
    async onSubmit() {
      const isValid = await this.$refs.clone_section_template.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      let finalPayload = {
        source_organisation_id: this.payload.source_organisation_id,
        target_organisation_id: this.payload.target_organisation_id,
      };
      if (this.payload.candidate_type_id && this.payload.candidate_type_id != "all")
        finalPayload.candidate_type_id = this.payload.candidate_type_id;
      this.clone_section_templates(finalPayload).then(() =>
        this.modalCallBack(true)
      );
    },
  },
};
</script>
