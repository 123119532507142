<template>
  <div class="pl-2">
    <CModal
      :title="modalTitle"
      color="primary"
      :show.sync="isShowPopup"
      :closeOnBackdrop="false"
      size="xl"
    >
      <template #header>
        <h6>{{ modalTitle }}</h6>
        <CButtonClose @click="modalCallBack(false)" />
      </template>

      <template #footer>
        <CButton
          form="section_template"
          color="secondary"
          @click="modalCallBack('Cancel')"
          >Cancel
        </CButton>
        <CButton form="section_template" color="primary" type="Submit"
          >Confirm
        </CButton>
      </template>

      <div>
        <ValidationObserver ref="section_template" v-slot="{ handleSubmit }">
          <form id="section_template" @submit.prevent="handleSubmit(onSubmit)">
            <CRow class="m-0">
              <CCol md="6" class="mb-3 pt-2">
                <CRow class="row">
                  <label class="required col-lg-12 col-md-12">
                    Section Title
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="section_name"
                        :value="payload.section_name"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" class="mb-3 pt-2" v-if="showGenericRadioButton">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12">
                    For All Candidate Types
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <RadioButton
                      name="for_all_candidate_types"
                      :value="payload.for_all_candidate_types"
                      :options="
                        options && options['boolean'] ? options['boolean'] : []
                      "
                      @change="handleChangeRadio"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="12" class="pb-1">
                <CRow class="row mb-3">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <RichTextEditor
                      name="section_desc"
                      :value="payload.section_desc"
                      @input="handleInput"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions } from "vuex";
import Modal from "@/components/reusable/Modal";
import TextInput from "@/components/reusable/Fields/TextInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import Select from "@/components/reusable/Fields/Select";
import RichTextEditor from "@/components/reusable/Fields/RichTextEditor";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });

export default {
  extends: Modal,
  name: "ModifySectionTemplates",
  props: [
    "modalCallBack",
    "modalTitle",
    "sectionData",
    "showGenericRadioButton",
    "candidateType",
  ],
  components: {
    TextInput,
    Select,
    RadioButton,
    RichTextEditor,
  },
  data() {
    return {
      payload: {},
    };
  },
  computed: {
    options() {
      return {
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: `No (only for ${this.candidateType})` },
        ],
      };
    },
  },
  watch: {
    isShowPopup() {
      if (this.isShowPopup === true) {
        this.payload = {};
      }
      this.$nextTick(() => {
        this.$refs.section_template.errors.clear;
        this.$refs.section_template.reset();
      });
    },
  },
  methods: {
    ...mapActions([
      "createJobSectionTemplate",
      "updateJobSectionTemplate",
      "fetchSectionByDisplayNames",
    ]),
    handleInput(name, value) {
      Vue.set(this.payload, name, value);
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }
      this.payload = {
        ...this.payload,
        [name]: val,
      };
    },
    async onSubmit() {
      const isValid = await this.$refs.section_template.validate();
      if (!isValid || !this.payload.section_desc) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      let appendAction = [];
      let {
        customer_id,
        organisation_id,
        candidate_type_id,
        section_id,
        for_all_candidate_types,
      } = this.payload;
      let finalPayload = {
        customer_id,
        organisation_id,
        section_name: this.payload?.section_name,
        section_desc: this.payload?.section_desc,
      };
      if (this.sectionData?.section_id) {
        appendAction = this.updateJobSectionTemplate({
          ...finalPayload,
          section_id,
        }).then((res) => {
          this.fetchSectionByDisplayNames({
            display_name: this.payload?.display_name,
            organisation_uid: organisation_id,
            customer_uid: customer_id,
            candidate_type_id: `${candidate_type_id},null`,
          });
        });
      } else {
        appendAction = this.createJobSectionTemplate({
          ...finalPayload,
          candidate_type_id:
            (this.showGenericRadioButton && for_all_candidate_types) ||
            this.payload?.display_name == "Organisation Description"
              ? null
              : candidate_type_id,
          display_order: this.payload?.display_order,
          display_name: this.payload?.display_name,
        }).then((res) => {
          this.fetchSectionByDisplayNames({
            display_name: this.payload?.display_name,
            organisation_uid: organisation_id,
            customer_uid: customer_id,
            candidate_type_id: `${candidate_type_id},null`,
          });
        });
      }
      Promise.all([appendAction]).then((res) => {
        this.modalCallBack(false);
      });
    },
  },
  mounted() {
    this.payload = {
      ...this.sectionData,
      // Replace used to remove unwanted code between two tags and Replace All used to remove the margin bottom of <p> tag
      section_desc: this.sectionData.section_desc
        ?.replace(/\>\s+\</g, "><")
        ?.replaceAll("<p>", '<p class="mb-0">'),
      section_name: this.sectionData?.section_name,
      for_all_candidate_types: false,
    };
  },
};
</script>
